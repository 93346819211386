import { default as GatewayBase } from './gateway-base';

export type Label = {
  id: string;
  value: string;
  accountId: string;
  source?: string;
};

export default class MetadataService extends GatewayBase {
  get notes() {
    return {
      create: this.createNote.bind(this),
      get: this.getNotes.bind(this),
    };
  }

  get metadata() {
    return {
      add: this.addMetadata.bind(this),
      get: this.getMetadata.bind(this),
    };
  }

  get tags() {
    return {
      create: this.createTag.bind(this),
      list: this.getTagsByAccountId.bind(this),
      query: this.queryTagsByAccountId.bind(this),
      subject: {
        add: this.addTagToSubject.bind(this),
        get: this.getTagsBySubjectId.bind(this),
        remove: this.removeTagFromSubject.bind(this),
      },
    };
  }

  private getTagsBySubjectId(subjectId: string) {
    return this.client.get<Label[]>(`v1/subjects/${subjectId}/labels`);
  }

  private addTagToSubject(input: {
    subjectId: string;
    tagId: string;
    storeId: string;
    userDisplayName?: string;
    userId?: string;
  }) {
    return this.client.post(`v1/subjects/${input.subjectId}/labels/${input.tagId}`, {
      storeId: input.storeId,
      userDisplayName: input.userDisplayName,
      userId: input.userId,
    });
  }

  private removeTagFromSubject(subjectId: string, tagId: string) {
    return this.client.delete(`v1/subjects/${subjectId}/labels/${tagId}`);
  }

  private getNotes(id: string) {
    return this.client.get(`v1/notes/${id}`);
  }

  private createNote(
    id: string,
    input: { username: string; userId: string; threadId?: string; content: string; media?: any[] },
  ) {
    return this.client.post(`v1/notes/${id}`, input);
  }

  private getMetadata(subjectId: string) {
    return this.client.get(`v1/metadata/${subjectId}`);
  }

  private addMetadata(
    subjectId: string,
    input: { key: string; value: string; addedByUserId?: string; addedByUsername?: string },
  ) {
    return this.client.post(`v1/metadata/${subjectId}`, input);
  }

  private queryTagsByAccountId(accountId: string, query: string) {
    return this.client.get<Label[]>(`v1/labels/${accountId}`, {
      params: {
        q: query,
      },
    });
  }

  private getTagsByAccountId(accountId: string) {
    return this.client.get<Label[]>(`v1/labels/${accountId}`);
  }

  private createTag(accountId: string, input: { name: string; source?: string }) {
    return this.client.post<Label>(`v1/labels/${accountId}`, input);
  }
}
