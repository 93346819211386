import GatewayBase from './gateway-base';

type Plan = {
  revSharePercentage: number;
  billingCycle: string;
};

export default class BilingRestService extends GatewayBase {
  get plans() {
    return {
      find: this.getPlan.bind(this),
      update: this.updatePlan.bind(this),
    };
  }

  private getPlan(storeId: string) {
    return this.client.get<Plan>(`v1/billing/${storeId}/plans`);
  }

  private updatePlan(storeId: string, input: Plan) {
    return this.client.put<Plan>(`v1/billing/${storeId}/plans`, input);
  }
}
