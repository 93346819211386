<template>
  <div v-if="checking" class="w-screen h-screen flex items-center justify-center">
    <PulseCircleLogo class="h-8 w-8" />
  </div>
  <div v-else class="h-screen w-screen flex items-center justify-center">
    <div class="text-center flex justify-center flex-col" style="width: 360px">
      <h3 class="mb-4 font-faktum">Something went wrong</h3>
      <p class="mb-8">This wasn't supposed to happen. Our team has been notified and we will get this fixed soon 🔧</p>
      <PulseButton @click="handleError">Go Home</PulseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue';

const props = defineProps({
  error: {
    required: true,
    type: Object,
  },
});

const checking = ref(true);

function handleError() {
  clearError({ redirect: '/claims/todo' });
}

onMounted(() => {
  console.error(props.error);
  Sentry.captureException(props.error);
  checking.value = false;
});
</script>
