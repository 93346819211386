import { gql } from '@apollo/client/core';

export const getOrder = gql`
  query Order($id: String!) {
    order(id: $id) {
      id
      sourceOrderId
      originalSourceOrderId
      originalOrderId
      sourceOrderNumber
      sourceCreatedAt
      sourceUpdatedAt
      sourceCancelledAt
      platformId
      premiumPaid
      currency
      eligibleForOta
      total
      discountTotal
      shippingCost
      shippingCostTax
      tax
      subtotal
      customerEmail
      customerName
      customerPhone
      exchangeRate
      storeId
      createdAt
      updatedAt
      deletedAt
      activities
      reshipments {
        id
        originalSourceOrderId
        sourceOrderId
        sourceOrderNumber
      }
      policyId
      orderItems {
        id
        orderId
        productName
        productImage
        sku
        quantity
        refundedQuantity
        price
        discount
        tax
        sourceItemId
        sourceProductId
        sourceVariantId
        createdAt
        updatedAt
        selectedOptions {
          name
          value
        }
      }
      fulfillments {
        id
        fulfillmentStatus
        trackingCompany
        trackingNumber
        trackingUrl
        items {
          quantity
          orderItemId
          item {
            price
            sourceItemId
            sourceVariantId
            productName
            productImage
            quantity
            sku
          }
        }
        createdAt
        updatedAt
      }
      address {
        id
        orderId
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        country
        type
        deletedAt
        customerId
      }
      customer {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const getOrders = gql`
  query getOrders(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $include: [String!]
    $storeId: [String!]
    $search: String
    $createdAtMin: DateTime
    $createdAtMax: DateTime
  ) {
    orders(
      after: $after
      first: $first
      before: $before
      last: $last
      include: $include
      filter: { storeId: $storeId, search: $search, createdAtMin: $createdAtMin, createdAtMax: $createdAtMax }
    ) {
      edges {
        node {
          id
          sourceOrderId
          sourceOrderNumber
          sourceCreatedAt
          sourceUpdatedAt
          sourceCancelledAt
          originalOrderId
          policyCancelledAt
          platformId
          premiumPaid
          currency
          total
          discountTotal
          shippingCost
          tax
          subtotal
          customerEmail
          customerName
          customerPhone
          exchangeRate
          storeId
          createdAt
          updatedAt
          deletedAt
          policyId
          eligibleForOta
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;

export const getOrdersNormal = gql`
  query getOrders($page: Int, $size: Int, $include: [String!], $createdAtMin: DateTime, $createdAtMax: DateTime) {
    normalOrders(
      page: $page
      size: $size
      include: $include
      filter: { createdAtMin: $createdAtMin, createdAtMax: $createdAtMax }
    ) {
      edges {
        node {
          id
          sourceOrderId
          sourceOrderNumber
          sourceCreatedAt
          sourceUpdatedAt
          sourceCancelledAt
          originalOrderId
          policyCancelledAt
          platformId
          premiumPaid
          currency
          total
          discountTotal
          shippingCost
          tax
          subtotal
          customerEmail
          customerName
          customerPhone
          exchangeRate
          storeId
          createdAt
          updatedAt
          deletedAt
          policyId
          eligibleForOta
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;

export const ordersCount = gql`
  query OrdersCount($storeId: [String!], $createdAtMin: DateTime, $createdAtMax: DateTime) {
    ordersCount(storeId: $storeId, filter: { createdAtMin: $createdAtMin, createdAtMax: $createdAtMax })
  }
`;

export const getOrderActivities = gql`
  query ActivitiesFromShopify($orderId: String!, $storeId: String!) {
    activitiesFromShopify(orderId: $orderId, storeId: $storeId) {
      edges {
        node {
          source
          sourceType
          activityType
          imageUrl
          description
          createdAt
        }
      }
    }
  }
`;
