import ApiBase from './base';

export default class OrderService extends ApiBase {
  async exportOrders(body: unknown) {
    const { data } = await this.client.post(`v1/export-orders`, body);

    return data;
  }

  async findByEmailAndSourceOrderId(email: string, sourceOrderId: string) {
    const { data } = await this.client.get('v1/order-lookup', {
      params: { email, order: sourceOrderId },
    });

    return data;
  }
}
