import { storeToRefs } from 'pinia';

import { useUserStore } from '~/store/user';

export default defineNuxtRouteMiddleware(async to => {
  onNuxtReady(() => {
    const user = useUserStore();
    const { isGlobalSupport } = storeToRefs(user);

    const globalSupportBlockedRoutes = ['/dashboard', '/payouts', '/team-members', '/settings'];

    if (to.fullPath.startsWith('/admin') && !can(can.adminUsers.read)) {
      return navigateTo('/unauthorized');
    }

    if (globalSupportBlockedRoutes.some(route => to.fullPath.includes(route)) && isGlobalSupport.value) {
      return navigateTo('/unauthorized');
    }
  });
});
