import { gql } from '@apollo/client/core';
export const updateUser = gql`
  mutation updateUser($id: String!, $input: UserUpdateDto!) {
    userUpdate(id: $id, input: $input) {
      firstName
      lastName
      email
      fullName
      nickname
      deletedAt
      roleId
    }
  }
`;

export const adminUserCreate = gql`
  mutation AddAdminUser($input: UserCreateInput!) {
    adminUserCreate(input: $input) {
      firstName
      lastName
      email
    }
  }
`;
