import { PlatformId } from '@shared/interfaces';

import GatewayBase from './gateway-base';
import type {
  GeneralSettingsType,
  PriceRuleSet,
  StoreContactSettings,
  StoreCreateObjects,
  ABTest,
  BrandingSetting,
  StoreUpdateObjects,
} from './types/merchant.types';
import type { PaginatedResponse } from '../../../../libs/shared/types';

export type Capabilities = {
  refund: boolean;
  reship: boolean;
  whitelabel: boolean;
  credit: boolean;
  inventory: boolean;
  splitTest: boolean;
  id: string;
  storeId: string;
};

export type CapabilitiesInput = Omit<Capabilities, 'id' | 'storeId'>;

export class MerchantRestService extends GatewayBase {
  get stores() {
    return {
      activate: this.activateStore.bind(this),
      admin: {
        list: this.getAdminStores.bind(this),
      },
      branding: {
        get: this.getBrandingSettings.bind(this),
        update: this.updateBrandingSettings.bind(this),
      },
      capabilities: {
        get: this.getCapabilities.bind(this),
        update: this.updateCapabilities.bind(this),
      },
      contact: {
        get: this.fetchContactSettings.bind(this),
        update: this.updateContactSettings.bind(this),
      },
      count: this.getStoreCounts.bind(this),
      create: this.createStore.bind(this),
      deactivate: this.moveToInactive.bind(this),
      develop: this.markAsDevelopment.bind(this),
      list: this.getStores.bind(this),
      metabase: this.getMetabaseUrl.bind(this),
      onboarding: this.markAsOnboarding.bind(this),
      products: {
        byName: this.getProductByName.bind(this),
      },
      settings: {
        general: {
          get: this.getGeneralSettings.bind(this),
          update: this.updateGeneralSettings.bind(this),
        },
        global: {
          get: this.getStoreSettings.bind(this),
          update: this.updateStoreSettings.bind(this),
        },
      },
      update: this.updateStore.bind(this),
    };
  }

  get tests() {
    return {
      create: this.createTest.bind(this),
      duplicate: this.duplicateTest.bind(this),
      end: this.endTest.bind(this),
      get: this.getTest.bind(this),
      list: this.getTests.bind(this),
      start: this.startTest.bind(this),
      update: this.updateTest.bind(this),
    };
  }

  private getProductByName(storeId: string, name: string) {
    return this.client.get<
      {
        id: unknown;
        productId: string | number;
        title: string;
        displayName: string;
        price: number;
        image: string;
        sku: string;
        selectedOptions: unknown[];
        inventoryQuantity: number;
        currency: string;
      }[]
    >(`v1/platforms/${storeId}/products?name=${name}`);
  }

  private getBrandingSettings(storeId: string) {
    return this.client.get<BrandingSetting>(`v1/stores/${storeId}/whitelabel`);
  }

  private updateBrandingSettings(storeId: string, body: Partial<BrandingSetting>) {
    return this.client.put<BrandingSetting>(`v1/stores/${storeId}/whitelabel`, body);
  }

  private getCapabilities(storeId: string) {
    return this.client.get<Capabilities>(`v1/stores/${storeId}/capabilities`);
  }

  private updateCapabilities(storeId: string, input: CapabilitiesInput) {
    return this.client.put<Capabilities>(`v1/stores/${storeId}/capabilities`, input);
  }

  private createTest(storeId: string, input: any) {
    return this.client.post(`/v1/stores/${storeId}/tests`, input);
  }

  private duplicateTest(storeId: string, testId: string) {
    return this.client.post<ABTest>(`/v1/stores/${storeId}/tests/${testId}/duplicate`);
  }

  private endTest(storeId: string, testId: string) {
    return this.client.post<ABTest>(`/v1/stores/${storeId}/tests/${testId}/end`);
  }

  private startTest(storeId: string, testId: string) {
    return this.client.post<ABTest>(`/v1/stores/${storeId}/tests/${testId}/start`);
  }

  private getTests(storeId: string) {
    return this.client.get<Omit<ABTest, 'results'>[]>(`/v1/stores/${storeId}/tests`);
  }

  private getTest(storeId: string, testId: string) {
    return this.client.get<ABTest>(`/v1/stores/${storeId}/tests/${testId}`);
  }

  private updateTest(input: { storeId: string; testId: string; data: any }) {
    return this.client.put<ABTest>(`/v1/stores/${input.storeId}/tests/${input.testId}`, input.data);
  }

  private getStoreSettings(storeId: string) {
    return this.client.get<{ storeLogoUrl: string; enableCoBranding: boolean; storeIndustry: string }>(
      `v1/stores/${storeId}/settings/global`,
    );
  }

  private updateStoreSettings(storeId: string, body: any) {
    return this.client.put(`v1/stores/${storeId}/settings/global`, body);
  }

  private getGeneralSettings(storeId: string) {
    return this.client.get<GeneralSettingsType>(`v1/stores/${storeId}/settings/general`);
  }

  private updateGeneralSettings(storeId: string, body: any) {
    return this.client.put(`v1/stores/${storeId}/settings/general`, body);
  }

  private fetchContactSettings(storeId: string) {
    return this.client.get<StoreContactSettings>(`v1/stores/${storeId}/settings/contacts`);
  }

  private updateContactSettings(storeId: string, body: unknown) {
    return this.client.put(`v1/stores/${storeId}/settings/contacts`, body);
  }

  private activateStore(id: string) {
    return this.client.post(`/v1/stores/${id}/activate`);
  }

  private markAsOnboarding(id: string) {
    return this.client.post(`/v1/stores/${id}/onboarding`);
  }

  private moveToInactive(id: string) {
    return this.client.post(`/v1/stores/${id}/deactivate`);
  }

  private markAsDevelopment(id: string) {
    return this.client.post(`/v1/stores/${id}/development`);
  }

  private getMetabaseUrl(id: string) {
    return this.client.get<string>(`/v1/stores/${id}/settings/metabase`);
  }

  private createStore<T extends keyof StoreCreateObjects>(input: StoreCreateObjects[T]) {
    return this.client.post<{
      id: string;
      name: string;
      slug: string;
      accountId: string;
      platformId: T;
      storeDomain: string;
    }>(`/v1/stores`, input);
  }

  private updateStore(storeId: string, input: StoreUpdateObjects) {
    return this.client.put<{ id: string }>(`/v1/stores/${storeId}`, input);
  }

  private getStores(params: object = {}) {
    return this.client.get('/v1/stores', { params });
  }

  private getAdminStores(params: object = {}) {
    return this.client.get<
      PaginatedResponse<{
        capabilities: object;
        generalSettings: object;
        storeSettings: object;
        contactSettings: object;
        id: string;
        name: string;
        slug: string;
        accountId: string;
        platformId: string;
        storeDomain: string;
      }>
    >('/v1/admin/stores', { params });
  }

  get pricing() {
    return {
      rules: this.getRules.bind(this),
      update: this.updatePriceRuleSet.bind(this),
      variant: this.getClosestVariant.bind(this),
    };
  }

  getClosestVariant(storeId: string, subtotal: number) {
    return this.client.get(`v1/quotes/${storeId}/variant?subtotal=${subtotal}`);
  }

  getRules(storeId: string) {
    return this.client.get<PriceRuleSet>(`v1/quotes/${storeId}/rules`);
  }

  getStoreCounts() {
    return this.client.get<{
      active: number;
      inactive: number;
      onboarding: number;
      dev: number;
    }>('/v1/stores/counts');
  }

  private updatePriceRuleSet(
    id: string,
    input: {
      storeId: string;
      payload: {
        rules?: {
          brandCalcMethod?: string;
          brandPrice?: number;
          customerCalcMethod?: string;
          customerPrice?: number;
          hybridOperator?: string;
          maxValue: number;
          minValue: number;
        }[];
        automaticallyAddToCart: boolean;
        mandatoryOp: boolean;
      };
    },
  ) {
    return this.client.put(`v1/quotes/${input.storeId}/rules/${id}`, input.payload);
  }
}
