import { can } from '~/composables/useEntitlements';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('can', {
    beforeMount(el, binding) {
      const entitlements = binding.value;

      if (can(...entitlements)) {
        return;
      }

      el.style.display = 'none';

      if (process.env.NODE_ENV !== 'production') {
        console.log('Access denied', entitlements);
      }
    },
  });
});
